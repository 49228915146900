









import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProductFinalPrice',
  props: {
    finalPrice: {
      type: Number,
      required: true,
    },
    isDiscounted: {
      type: Boolean,
      default: false,
    },
  },
});
