

















import { computed, defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { BloomreachProduct } from '~/types/product/BloomreachProduct';
import { LinkType } from '~/types/components/Link/LinkType';
import { cleanBloomreachImageUrl, prepareBloomreachProductPath } from '~/helpers/bloomreach/bloomreachData';
import MiniProductCard from '~/components/molecules/Product/MiniProductCard/MiniProductCard.vue';
import { SIMPLIFIED_CARD } from '~/constants/productCard';
import type { EnergyRatingDetails } from '~/types/components/EnergyRating/EnergyRatingDetails';
import { ENERGY_RATING_SIZE } from '~/constants/energyRating';

export default defineComponent({
  name: 'BloomreachProductCart',
  components: {
    MiniProductCard,
  },
  props: {
    product: {
      type: Object as PropType<BloomreachProduct>,
      required: true,
    },
    productBoxTypeTag: {
      type: String,
      required: true,
    },
    energyRating: {
      type: Object as PropType<EnergyRatingDetails>,
      default: (): EnergyRatingDetails => ({}),
    },
  },
  setup(props) {
    const cleanedImageUrl = computed(() => cleanBloomreachImageUrl(props.product.image));
    const productUrlPath = computed(() => prepareBloomreachProductPath(props.product.url));
    const price = computed(() => {
      return props.product.price_local_currency;
    });
    const showEnergyRating = computed(() => {
      return !!props.energyRating?.rating;
    });

    return {
      cleanedImageUrl,
      productUrlPath,
      LinkType,
      SIMPLIFIED_CARD,
      price,
      showEnergyRating,
      ENERGY_RATING_SIZE,
    };
  },
});
