









import { defineComponent } from '@nuxtjs/composition-api';
import ModalHeading from '@/components/molecules/ModalHeading.vue';

export default defineComponent({
  name: 'CartSidebarHeading',
  components: {
    ModalHeading,
  },
});
