














import { defineComponent } from '@nuxtjs/composition-api';
import {
  SfSidebar,
} from '@storefront-ui/vue';
export default defineComponent({
  name: 'SideBar',
  components: {
    SfSidebar,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    closeHandler: {
      type: Function,
      default: () => {},
    },
  },
});
