export const PRODUCT_CARD = {
  IMAGE_HEIGHT: 300,
  IMAGE_WIDTH: 320,
} as const;

export const VERTICAL_PRODUCT_CARD = {
  IMAGE_HEIGHT: 100,
  IMAGE_WIDTH: 100,
} as const;

export const SIMPLIFIED_CARD = {
  IMAGE_HEIGHT: 120,
  IMAGE_WIDTH: 120,
} as const;

export const MEDIUM_CARD = {
  IMAGE_HEIGHT: 230,
  IMAGE_WIDTH: 230,
} as const;

export const TILE_MINI_CARD = {
  IMAGE_HEIGHT: 46,
  IMAGE_WIDTH: 46,
} as const;
