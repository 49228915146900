













import { defineComponent, computed } from '@nuxtjs/composition-api';
import { isProduction } from '~/helpers/env/checkEnvironmentType';

export default defineComponent({
  name: 'BloomreachEnvironmentWarning',
  setup() {
    const BLOOMREACH_MISSING_CONFIG_WARNING =
      'Bloomreach products are only clickable in the German shop in this environment.';
    const isProductionEnvironment = computed(() => isProduction());

    return {
      isProductionEnvironment,
      BLOOMREACH_MISSING_CONFIG_WARNING,
    };
  },
});
